import { type OverridableStringUnion } from '@mui/types'
import { TextFieldPropsSizeOverrides, MenuItem, TextField } from '@mui/material'

export type OptionValue = string | number

export type Option = {
  label: string
  value: OptionValue | undefined
  Icon?: JSX.Element | null
}

type SelectorProps = {
  value: unknown
  options: Option[]
  onChange: (value: OptionValue | undefined) => void
  disabled?: boolean
  label?: string
  nolabel?: boolean
  optionKey?: string
  fullWidth?: boolean
  size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>
}

const INPUT_WIDTH = 160

export function Selector(props: SelectorProps) {
  const {
    value,
    options,
    onChange,
    disabled,
    label = '',
    optionKey,
    fullWidth = false,
    nolabel = false,
    size = 'medium',
  } = props

  const extraProps = optionKey ? { key: optionKey } : {}

  return (
    <TextField
      {...extraProps}
      size={size}
      disabled={disabled}
      select
      label={nolabel ? '' : label}
      fullWidth={fullWidth}
      value={value}
      InputLabelProps={{ shrink: true }}
      inputProps={{
        sx: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
        },
      }}
      SelectProps={{
        native: false,
        sx: { minWidth: nolabel ? '60px' : '120px' },
        MenuProps: {
          sx: { '& .MuiPaper-root': { maxHeight: 260 } },
        },
      }}
      sx={{
        maxWidth: fullWidth ? {} : { md: nolabel ? '60px' : INPUT_WIDTH },
        // textTransform: 'capitalize',
      }}
    >
      {options.map((option, index) => {
        const { Icon } = option

        return (
          <MenuItem
            key={`option-${index}`}
            value={option.value}
            onClick={() => {
              if (onChange) {
                onChange(option.value)
              }
            }}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              // textTransform: 'capitalize',
              alignItems: 'center',
            }}
          >
            {/* {!Icon ? null : cloneElement(Icon)} */}
            {Icon}
            {nolabel ? '' : option.label}
          </MenuItem>
        )
      })}
    </TextField>
  )
}

export default Selector
